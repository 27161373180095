<template>
    <div class="card card-custom">
        <div class="card-body p-0">
            <!--begin: Wizard-->
            <div class="wizard wizard-2" id="kt_wizard_v2" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Wizard Nav -->
                <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
                    <div class="wizard-steps">
                        <div class="wizard-step" data-wizard-type="step" data-wizard-state="current">
                            <div class="wizard-label">
                                <i class="wizard-icon far fa-user-circle"></i>
                                <div>
                                    <h3 class="wizard-title">1. PATIENT DETAILS</h3>
                                    <span class="text-muted">Office Visit Info</span>
                                </div>
                            </div>
                        </div>
                        <div class="wizard-step" data-wizard-type="step">
                            <div class="wizard-label">
                                <i class="wizard-icon fas fa-history"></i>
                                <div>
                                    <h3 class="wizard-title">2. MEDICAL HISTORY</h3>
                                    <span class="text-muted">Subjective</span>
                                </div>
                            </div>
                        </div>
                        <div class="wizard-step" href="#" data-wizard-type="step">
                            <div class="wizard-label">
                                <i class="wizard-icon fas fa-th-list"></i>
                                <div>
                                    <h3 class="wizard-title">3. REVIEW OF SYSTEM</h3>
                                    <span class="text-muted">Subjective</span>
                                </div>
                            </div>
                        </div>
                        <div class="wizard-step" data-wizard-type="step">
                            <div class="wizard-label">
                                <i class="wizard-icon fas fa-heartbeat"></i>
                                <div>
                                    <h3 class="wizard-title">4. VITAL SIGNS</h3>
                                    <span class="text-muted">Objective</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end: Wizard Nav -->

                <!--begin: Wizard Body -->
                <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
                    <!--begin: Wizard Form-->
                    <div class="row">
                        <div class="col-xxl-12">
                            <form class="form" id="kt_form" ref="formNurse">
                                <!--begin: Wizard Step 1-->
                                <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                    <validation-observer tag="form">
                                        <PatientDetails :detailPatient="detailPatient" :roomOptions="roomOptions" />
                                    </validation-observer>
                                </div>
                                <!--end: Wizard Step 1-->

                                <!--begin: Wizard Step 2-->
                                <div class="pb-5" data-wizard-type="step-content">
                                    <MedicalHistory v-if="medicalHistoryConditional" :medicalHistory="medicalHistory"
                                        :allergiesOptions="allergiesOptions" :ailmentOptions="ailmentOptions"
                                        :medicationsOptions="medicationsOptions"
                                        :medicationsPatientOptions="medicationsPatientOptions" />
                                </div>
                                <!--end: Wizard Step 2-->

                                <!--begin: Wizard Step 3-->
                                <div class="pb-5" data-wizard-type="step-content">
                                    <ReviewSystem :reviewSystemSelected="reviewSystemSelected"
                                        :reviewSystemLength="reviewSystemLength" v-model="describe_review_system" />
                                </div>
                                <!--end: Wizard Step 3-->

                                <!--begin: Wizard Step 4-->
                                <div class="pb-5" data-wizard-type="step-content">
                                    <VitalSign :vitalSign="vitalSign" :signVitalsOptions="signVitalsOptions" />
                                </div>
                                <!--end: Wizard Step 4-->
                                {{ wizardForm }}

                                <!--begin: Wizard Actions -->
                                <div class="d-flex justify-content-between border-top pt-10">
                                    <div class="mr-2">
                                        <button class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                                            data-wizard-type="action-prev">
                                            Previous
                                        </button>
                                    </div>
                                    <div>
                                        <button v-on:click="submit"
                                            class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                                            data-wizard-type="action-submit" :disabled="sbmLoading">
                                            Submit
                                        </button>
                                        <button class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                                            data-wizard-type="action-next">
                                            Next Step
                                        </button>
                                    </div>
                                </div>
                                <!--end: Wizard Actions -->
                            </form>
                        </div>
                        <!--end: Wizard-->
                    </div>
                </div>
                <!--end: Wizard Body -->
            </div>
            <ToastNotification ref="toast" />
            <!--end: Wizard-->
        </div>
    </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import { ValidationObserver } from "vee-validate";
import ToastNotification from "@/components/ToastNotification";

import PatientDetails from "./components/PatientDetails.vue";
import MedicalHistory from "./components/MedicalHistory.vue";
import ReviewSystem from "./components/ReviewSystem.vue";
import VitalSign from "./components/VitalSign.vue";

import axiosOV from "@/core/services/api/office-visit";
import axiosPA from "@/core/services/api/patient";

export default {
    name: "Wizard-2",
    components: {
        ValidationObserver,
        ToastNotification,

        PatientDetails,
        MedicalHistory,
        ReviewSystem,
        VitalSign,
    },
    data() {
        return {
            describe_review_system: '',
            detailPatient: {
                type_visits_id: 0,
                reason_visit: '',
                gender: '',
                name: "",
                lastName: "",
                phone: "",
                dateBirth: null,
                facility: null,
                room: [],
            },
            medicalHistory: {
                ailments: [],
                allergies: [],
                medicationInformation: [],
                current_medications: false,
                userId: null,
                historyId: null,
            },
            vitalSign: [],
            ailmentOptions: {
                data: null,
            },
            allergiesOptions: {
                data: null,
            },
            medicationsOptions: {
                data: null,
            },
            medicationsPatientOptions: {
                data: null,
            },
            reviewSystemLength: {
                data: null,
            },
            reviewSystemSelected: {},
            roomOptions: [],
            signVitalsOptions: [],
            loading: false,
            sbmLoading: false,
            medicalHistoryConditional: false,
            wizardForm: null,
        };
    },
    mounted() {
        this.getDetails();
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Office Visit", route: "../table" },
            { title: "Nurse" },
        ]);

        // Initialize form wizard
        const wizard = new KTWizard("kt_wizard_v2", {
            startStep: 1, // initial active step number
            clickableSteps: true, // allow step clicking
        });

        const formNurse = this.$refs.formNurse;

        // Change event
        wizard.on("submit", function (wizardObj, formNurse) {
            if (wizardObj.currentStep === 1) {
                /* const isValidated = this.$refs.patientRef.validate()
                console.log(isValidated); */
            }

            setTimeout(() => {
                KTUtil.scrollTop();
            }, 500);
        });
    },
    methods: {
        async submit(e) {
            e.preventDefault();

            try {
                this.sbmLoading = true
                const finallyReviewSystem = this.flattenReviewSystem();

                const data = {
                    room: this.detailPatient.room,
                    vital_signs: this.vitalSign,
                    review_of_system: finallyReviewSystem,
                    describe_review_system: this.describe_review_system,
                    history: {
                        ...this.medicalHistory,
                    },
                };

                await this.checkRoomAvailability();
                const patientDetail = await this.getPatientDetail();

                this.updateReasonVisit();
                await this.updatePatient(patientDetail);
                await this.performNurseProcedure(data);

                this.$refs.toast.success("The nurse procedure was completed");
                this.showSuccessMessage();
                this.sbmLoading = false
                this.$router.go(-1);
            } catch (error) {
                this.sbmLoading = false
                this.handleErrors(error);
            }
        },
        async checkRoomAvailability() {
            try {
                await axiosOV.roomsCheckAvailable(this.$route.params.id, this.detailPatient.room);
            } catch (error) {
                throw new Error("The room has been assigned to another person");
            }
        },
        async getPatientDetail() {
            try {
                const { registro } = await axiosPA.patientDetail(this.medicalHistory.userId);
                registro.gender = this.detailPatient.gender;
                return registro;
            } catch (error) {
                throw new Error("Check the reason visit of the patient");
            }
        },
        async updateReasonVisit() {
            try {
                await axiosOV.updateReasonVisit(this.detailPatient.appointmentsId, {
                    reason_visit: this.detailPatient.reason_visit,
                });
            } catch (error) {
                throw new Error("Check the reason visit of the patient");
            }
        },
        async updatePatient(patientDetail) {
            try {
                await axiosPA.patientUpdate(this.medicalHistory.userId, patientDetail);
            } catch (error) {
                throw new Error("Check the gender of the patient");
            }
        },
        async performNurseProcedure(data) {
            try {
                const response = await axiosOV.procedureNurse(this.$route.params.id, data);
                if (response.status === 'ERROR') {
                    throw new Error(response.message);
                }
            } catch (error) {
                throw new Error("The procedure has not been updated");
            }
        },
        flattenReviewSystem() {
            const reviewSystemSelected = this.reviewSystemSelected;
            return Object.values(reviewSystemSelected).flat();
        },
        showSuccessMessage() {
            Swal.fire({
                title: "",
                text: "Patient ready to be evaluated by OMA!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
            });
        },
        handleErrors(error) {
            if (error instanceof Error) {
                this.$refs.toast.danger(error.message);
            } else {
                console.error(error); // Log unexpected errors for debugging
                this.$refs.toast.danger("An unexpected error occurred");
            }
        },
        getDetails() {
            this.loading = true;
            axiosOV
                .dataForNurse(this.$route.params.id)
                .then(({ all, office_visit, paginates, rooms }) => {
                    if (office_visit.visit_statuses_id == 5 || office_visit.visit_statuses_id == 6) return this.$router.go(-1);
                    const { type_vital_sign, review_of_system: CategoryReviewSystem } =
                        all;

                    this.signVitalsOptions = type_vital_sign;
                    this.vitalSign = this.signVitalsOptions.map((item) => {
                        return {
                            id: item.id,
                            value: null,
                        };
                    });

                    this.roomOptions = rooms.map((room) => {
                        return {
                            id: room,
                            name: room,
                        };
                    });

                    const {
                        appointments: { id, patients, facilities, reason_visit, type_visits_id },
                        room,
                        type_vital_sign: VitalSignUser,
                        review_of_system
                    } = office_visit;
                    const rum = []
                    room != undefined || room != null ? rum.push(room) : ''
                    this.detailPatient = {
                        type_visits_id,
                        appointmentsId: id,
                        reason_visit: reason_visit,
                        gender: patients.gender,
                        name: patients.name,
                        lastName: patients.lastname,
                        phone: patients.phone,
                        dateBirth: patients.date_birth,
                        facility: facilities.name,
                        room: rum
                    };

                    const { histories } = patients;
                    this.medicalHistory = {
                        ailments: histories.ailment.map((item) => item.id),
                        allergies: histories.allergies.map((item) => item.id),
                        medicationInformation: histories.medication_informations,
                        current_medications: histories.current_medications,
                        userId: histories.users_id,
                        historyId: histories.id,
                    };

                    const { ailments, allergies, medication, medication_patient } =
                        paginates;

                    this.ailmentOptions.data = ailments.data;
                    this.allergiesOptions.data = allergies.data;
                    this.medicationsOptions.data = medication.data;
                    this.medicationsPatientOptions.data =
                        medication_patient.original.registro.data;

                    this.medicalHistoryConditional = true;

                    let nuevoObjeto = {};

                    review_of_system.forEach((x) => {
                        if (!nuevoObjeto.hasOwnProperty(x.category.name)) {
                            nuevoObjeto[x.category.name] = [];
                        }
                        nuevoObjeto[x.category.name].push(x.assessmentable_id);
                    });

                    this.reviewSystemSelected = nuevoObjeto;
                    this.reviewSystemLength.data = CategoryReviewSystem;
                    // return console.log(VitalSignUser)
                    VitalSignUser.forEach((elem, i) => {
                        this.vitalSign[i].value = elem.pivot.value;
                    });

                    this.loading = false;
                })
                .catch((error) => {
                    this.$router.go(-1);
                    console.log(error);
                });
        },
        getValidation() {
            console.log("Hello 2");
        },
        validationFormPatient() {
            return new Promise((resolve, reject) => {
                this.$refs.patientRef.validate().then((success) => {
                    if (success) {
                        resolve(true);
                    } else {
                        reject();
                        wizardObj.stop();
                    }
                });
            });
        },
    },
};
</script>
